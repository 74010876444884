<template>
  <div>
    <nav id="desktop-nav">
      <div class="logo">Philippe Noël</div>
      <div>
        <ul class="nav-links">
          <li><a href="#about">À propos</a></li>
          <li><a href="#experience">Expérience</a></li>
          <li><a href="#projects">Projets</a></li>
          <li><a href="#contact">Contact</a></li>
        </ul>
      </div>
    </nav>
    <nav id="hamburger-nav">
      <div class="logo">Philippe Noël</div>
      <div class="hamburger-menu">
        <div class="hamburger-icon" @click="toggleMenu">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <ul class="menu-links" v-if="menuOpen">
          <li><a href="#about" @click="toggleMenu">À propos</a></li>
          <li><a href="#experience" @click="toggleMenu">Expérience</a></li>
          <li><a href="#projects" @click="toggleMenu">Projets</a></li>
          <li><a href="#contact" @click="toggleMenu">Contact</a></li>
        </ul>
      </div>
    </nav>
    <section id="profile">
      <div class="section__pic-container">
        <img class="rounded" src="https://avatars.githubusercontent.com/u/87782956?v=4" alt="Photo de profil de Philippe Noël" />
      </div>
      <div class="section__text">
        <p class="section__text__p1">Bonjour, je suis</p>
        <h1 class="title">Philippe Noël</h1>
        <p class="section__text__p2">Développeur Frontend</p>
        <div class="btn-container">
          <button class="btn btn-color-2" @click="openResume">Télécharger CV</button>
          <button class="btn btn-color-1" @click="scrollToContact">Infos de Contact</button>
        </div>
        <div id="socials-container">
          <img
            src="./assets/linkedin.png"
            alt="Mon profil LinkedIn"
            class="icon"
            @click="openLinkedIn"
          />
          <img
            src="./assets/github.png"
            alt="Mon profil GitHub"
            class="icon"
            @click="openGitHub"
          />
        </div>
      </div>
    </section>
    <section id="about">
      <p class="section__text__p1">En savoir plus</p>
      <h1 class="title">À propos de moi</h1>
      <div class="section-container">
        <div class="section__pic-container">
          <img src="./assets/about-pic.jpeg" alt="Photo de profil" class="about-pic" />
        </div>
        <div class="about-details-container">
          <div class="about-containers">
            <div class="details-container">
              <img src="./assets/experience.png" alt="Icône d'expérience" class="icon" />
              <h3>Expérience</h3>
              <p>{{ this.experienceTime() }} ans <br />Développement Frontend</p>
            </div>
            <div class="details-container">
              <img src="./assets/education.png" alt="Icône d'éducation" class="icon" />
              <h3>Éducation</h3>
              <p>Technique en informatique</p>
            </div>
          </div>
          <div class="text-container">
            <p>
              En complétant ma technique en informatique, j'ai effectué trois stages en développement web chez <a href="https://biotwin.ai/" target="_blank"><b>BioTwin</b></a>. J'ai continué à travailler chez Biotwin à temps partiel entre les stages.
            </p>
          </div>
        </div>
      </div>
      <img src="./assets/arrow.png" alt="Icône flèche" class="icon arrow" @click="scrollToExperience" />
    </section>
    <section id="experience">
      <p class="section__text__p1">Explorez mon</p>
      <h1 class="title">Expérience</h1>
      <div class="experience-details-container">
        <div class="about-containers">
          <div class="details-container">
            <h2 class="experience-sub-title">Développement Frontend</h2>
            <div class="article-container">
              <article v-for="(skill, index) in frontendSkills" :key="index">
                <img src="./assets/checkmark.png" alt="Icône d'expérience" class="icon" />
                <div>
                  <h3>{{ skill.name }}</h3>
                  <p>{{ skill.level }}</p>
                </div>
              </article>
            </div>
          </div>
          <div class="details-container">
            <h2 class="experience-sub-title">Autres compétences</h2>
            <div class="article-container">
              <article v-for="(skill, index) in backendSkills" :key="index">
                <img src="./assets/checkmark.png" alt="Icône d'expérience" class="icon" />
                <div>
                  <h3>{{ skill.name }}</h3>
                  <p>{{ skill.level }}</p>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
      <img src="./assets/arrow.png" alt="Icône flèche" class="icon arrow" @click="scrollToProjects" />
    </section>
    <section id="projects">
      <p class="section__text__p1">Découvrez mes derniers</p>
      <h1 class="title">Projets</h1>
      <div class="experience-details-container">
        <div class="about-containers">
          <div class="details-container color-container" v-for="(project, index) in projects" :key="index">
            <div class="article-container">
              <img class="full-width" :src="getImgUrl(project.image)" v-bind:alt="pic">
            </div>
            <h2 class="experience-sub-title project-title">{{ project.title }}</h2>
            <div class="btn-container">
              <!-- <button class="btn btn-color-2 project-btn" @click="openLink(project.github)">Github</button> -->
              <button class="btn btn-color-2 project-btn" @click="openLink(project.demo)">Démo en direct</button>
            </div>
          </div>
          <div>
  </div>
        </div>
      </div>
      <img src="./assets/arrow.png" alt="Icône flèche" class="icon arrow" @click="scrollToContact" />
    </section>
    <section id="contact">
      <p class="section__text__p1">Entrer en contact</p>
      <h1 class="title">Contactez-moi</h1>
      <div class="contact-info-upper-container">
        <div class="contact-info-container">
          <img src="./assets/email.png" alt="Icône email" class="icon contact-icon email-icon" />
          <p><a href="mailto:examplemail@gmail.com">services.web.noel@gmail.com</a></p>
        </div>
    
      </div>
    </section>
    <footer>
      <nav>
        <div class="nav-links-container">
          <ul class="nav-links">
            <li><a href="#about">À propos</a></li>
            <li><a href="#experience">Expérience</a></li>
            <li><a href="#projects">Projets</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </div>
      </nav>
      <p>Droit d'auteur &#169; 2023 Philippe Noël. Tous droits réservés.</p>
    </footer>
  </div>
</template>

<script>
export default  {
    name: 'PortfolioComponent',

  data() {
    return {
      menuOpen: false,
      frontendSkills: [
        { name: 'HTML', level: 'Expérimenté' },
        { name: 'CSS', level: 'Expérimenté' },
        { name: 'TypeScript', level: 'Intermédiaire' },
        { name: 'Vue JS', level: 'Débutant' },
        { name: 'Angular', level: 'Intermédiaire' },
        { name: 'React', level: 'Intermédiaire' },
        { name: 'Strapi', level: 'Intermédiaire' },
      ],
      backendSkills: [
        { name: 'PostgreSQL', level: 'Intermédiaire' },
        { name: 'Node JS', level: 'Intermédiaire' },
        { name: 'Express JS', level: 'Intermédiaire' },
        { name: 'Git', level: 'Intermédiaire' },
        { name: 'Docker', level: 'Débutant' },
        { name: 'Socket.IO', level: 'Débutant' },


      ],
      projects: [
        { title: 'Bookbox', image: 'bookbox', github: 'https://github.com/', demo: 'https://deploy-preview-10--bookboxproject.netlify.app' },
        { title: 'Page de restaurant responsive', image: 'restaurant', github: 'https://gitlab.com/philippe_noel111/tp3web', demo: 'https://tp3web.vercel.app/' },
      ],
    };
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    openResume() {
      window.open('/CV.pdf', '_blank');
    },
    scrollToContact() {
      document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
    },
    openLinkedIn() {
  window.open('https://www.linkedin.com/in/philippe-no%C3%ABl-6b2b9b204/', '_blank');
},
openGitHub() {
  window.open('https://github.com/philippenoel315', '_blank');
},

experienceTime(){
  let date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth();
  let experience = year - 2022;
  if(month < 6){
    experience -= 1;
  }
  return experience;
},

getImgUrl(pet) {
    var images = require.context('./assets/', false, /\.png$/)
    return images('./' + pet + ".png")
  },

    scrollToExperience() {
      document.getElementById('experience').scrollIntoView({ behavior: 'smooth' });
    },
    scrollToProjects() {
      document.getElementById('projects').scrollIntoView({ behavior: 'smooth' });
    },
    openLink(url) {
  window.open(url, '_blank');
}

  },
};
</script>

<style scoped>
/* GÉNÉRAL */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Poppins", sans-serif;
}

html {
  scroll-behavior: smooth;
}

p {
  color: rgb(85, 85, 85);
}

/* TRANSITION */

a,
.btn {
  transition: all 300ms ease;
}

/* NAVIGATION DESKTOP */

nav,
.nav-links {
  display: flex;
}

nav {
  justify-content: space-around;
  align-items: center;
  height: 17vh;
}

.nav-links {
  gap: 2rem;
  list-style: none;
  font-size: 1.5rem;
}

a {
  color: black;
  text-decoration: none;
  text-decoration-color: white;
}

a:hover {
  color: grey;
  text-decoration: underline;
  text-underline-offset: 1rem;
  text-decoration-color: rgb(181, 181, 181);
}

.logo {
  font-size: 2rem;
}

.full-width {
  width: 100%;
  height: 200px;
}

.logo:hover {
  cursor: default;
}

/* MENU HAMBURGER */

#hamburger-nav {
  display: none;
}

.hamburger-menu {
  position: relative;
  display: inline-block;
}

.hamburger-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 24px;
  width: 30px;
  cursor: pointer;
}

.hamburger-icon span {
  width: 100%;
  height: 2px;
  background-color: black;
  transition: all 0.3 ease-in-out;
}

.menu-links {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  width: fit-content;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3 ease-in-out;
}

.menu-links a {
  display: block;
  padding: 10px;
  text-align: center;
  font-size: 1.5rem;
  color: black;
  text-decoration: none;
  transition: all 0.3 ease-in-out;
}

.menu-links li {
  list-style: none;
}

.menu-links.open {
  max-height: 300px;
}

.hamburger-icon.open span:first-child {
  transform: rotate(45deg) translate(10px, 5px);
}

.hamburger-icon.open span:nth-child(2) {
  opacity: 0;
}

.hamburger-icon.open span:last-child {
  transform: rotate(-45deg) translate(10px, -5px);
}

.hamburger-icon span:first-child {
  transform: none;
}

.hamburger-icon span:first-child {
  opacity: 1;
}

.hamburger-icon span:first-child {
  transform: none;
}

/* SECTIONS */

section {
  padding-top: 4vh;
  height: 96vh;
  margin: 0 10rem;
  box-sizing: border-box;
  min-height: fit-content;
}

.section-container {
  display: flex;
}

/* SECTION PROFIL */

#profile {
  display: flex;
  justify-content: center;
  gap: 5rem;
  height: 80vh;
}

.section__pic-container {

  display: flex;
  height: 400px;
  width: 400px;
  margin: auto 0;
}


.rounded {
  border-radius: 50% !important;

  object-fit: cover;
}

.section__text {
  align-self: center;
  text-align: center;
}

.section__text p {
  font-weight: 600;
}

.section__text__p1 {
  text-align: center;
}

.section__text__p2 {
  font-size: 1.75rem;
  margin-bottom: 1rem;
}

.title {
  font-size: 3rem;
  text-align: center;
}

#socials-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  gap: 1rem;
}

/* ICÔNES */

.icon {
  cursor: pointer;
  height: 2rem;
}

/* BOUTONS */

.btn-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.btn {
  font-weight: 600;
  transition: all 300ms ease;
  padding: 1rem;
  width: 8rem;
  border-radius: 2rem;
}

.btn-color-1,
.btn-color-2 {
  border: rgb(53, 53, 53) 0.1rem solid;
}

.btn-color-1:hover,
.btn-color-2:hover {
  cursor: pointer;
}

.btn-color-1,
.btn-color-2:hover {
  background: rgb(53, 53, 53);
  color: white;
}

.btn-color-1:hover {
  background: rgb(0, 0, 0);
}

.btn-color-2 {
  background: none;
}

.btn-color-2:hover {
  border: rgb(255, 255, 255) 0.1rem solid;
}

.btn-container {
  gap: 1rem;
}

/* SECTION À PROPOS */

#about {
  position: relative;
}

.about-containers {
  gap: 2rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.about-details-container {
  justify-content: center;
  flex-direction: column;
}

.about-containers,
.about-details-container {
  display: flex;
}

.about-pic {
  border-radius: 2rem;
}

.arrow {
  position: absolute;
  right: -5rem;
  bottom: 2.5rem;
}

.details-container {
  padding: 1.5rem;
  flex: 1;
  background: white;
  border-radius: 2rem;
  border: rgb(53, 53, 53) 0.1rem solid;
  border-color: rgb(163, 163, 163);
  text-align: center;
}

.section-container {
  gap: 4rem;
  height: 80%;
}

.section__pic-container {
  height: 400px;
  width: 400px;
  margin: auto 0;
}

/* SECTION EXPÉRIENCE */

#experience {
  position: relative;
}

.experience-sub-title {
  color: rgb(85, 85, 85);
  font-weight: 600;
  font-size: 1.75rem;
  margin-bottom: 2rem;
}

.experience-details-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.article-container {
  display: flex;
  text-align: initial;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 2.5rem;
  justify-content: space-around;
}

article {
  display: flex;
  width: 10rem;
  justify-content: space-around;
  gap: 0.5rem;
}

article .icon {
  cursor: default;
}

/* SECTION PROJETS */

#projects {
  position: relative;
}

.color-container {
  border-color: rgb(163, 163, 163);
  background: rgb(250, 250, 250);
}

.project-img {
  border-radius: 2rem;
  width: 90%;
  height: 90%;
}

.project-title {
  margin: 1rem;
  color: black;
}

.project-btn {
  color: black;
  border-color: rgb(163, 163, 163);
}

/* CONTACT */

#contact {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 70vh;
}

.contact-info-upper-container {
  display: flex;
  justify-content: center;
  border-radius: 2rem;
  border: rgb(53, 53, 53) 0.1rem solid;
  border-color: rgb(163, 163, 163);
  background: (250, 250, 250);
  margin: 2rem auto;
  padding: 0.5rem;
}

.contact-info-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin: 1rem;
}

.contact-info-container p {
  font-size: larger;
}

.contact-icon {
  cursor: default;
}

.email-icon {
  height: 2.5rem;
}

/* SECTION PIED DE PAGE */

footer {
  height: 26vh;
  margin: 0 1rem;
}

footer p {
  text-align: center;
}

</style>
