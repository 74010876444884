<template>
  <div v-if="showModal" class="modal">
    <div class="modal-content">
      <span class="close" @click="closeModal">&times;</span>
      <h2>Créer un client</h2>
      <form @submit.prevent="createClient" class="max-width">
        <div class="form-group">
          <input
            type="text"
            id="name"
            class="form-control ms-2"
            placeholder="Nom"
            v-model="client.name"
            required
          />
        </div>
        <div class="form-group">
          <input
            type="email"
            id="email"
            class="form-control ms-2"
            placeholder="Courriel"
            v-model="client.email"
            required
          />
        </div>
        <div class="form-group">
          <input
            type="text"
            id="address"
            class="form-control ms-2"
            placeholder="Addresse"
            v-model="client.address"
            required
          />
        </div>
        <div class="form-group">
          <input
            type="text"
            id="address2"
            class="form-control ms-2"
            placeholder="Addresse 2"
            v-model="client.address2"
          />
        </div>

        <button type="submit" class="btn btn-black">Créer le client</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddClientComponent",
  data() {
    return {
      showModal: true,
      client: {
        name: "",
        email: "",
        address: "",
        address2: "",
      },
    };
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
    async createClient() {
      try {
        const clientData = {
          name: this.client.name,
          email: this.client.email,
          address: this.client.address,
          address2: this.client.address2,
        };
        const response = await fetch(
          `${process.env.VUE_APP_BACKEND_URL}/clients`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(clientData),
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Client created successfully:", data);
        this.closeModal();

        return data;
      } catch (error) {
        console.error("Error creating client:", error);
      }
      this.closeModal();
    },
  },
};
</script>

<style scoped>
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  margin-right: 20px;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.form-group {
  margin-bottom: 20px;
}

.btn-submit {
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}
</style>
./AddClientComponent.vue
