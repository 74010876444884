<template>
  <div class="content-wrapper">
    <!-- Removed for brevity: <HomeComponent/> -->
    <div class="container">
      <router-view></router-view>
    </div>
  </div>
  <!-- <footer class="footer">
    <div class="container">
      <span class="text-muted">services.web.noel@gmail.com</span>
    </div>
  </footer> -->
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
// import HomeComponent from './Home.vue';

export default {
  name: "App",
  components: {
    // HomeComponent
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  text-align: center;
  margin-top: 60px;
}

button {
  color: white;
  background-color: black;
}

/* .window {
  height: 85vh;
  margin: 0;
} */

.max-width {
  max-width: 500px;
  margin: auto;
}

.max-width-700 {
  max-width: 700px;
  margin: auto;
}

.margin > * {
  margin-top: 20px;
  margin-bottom: 10px;
}

.btn-black {
  background-color: #000 !important;
  color: #fff !important;
  border: none !important;
}

.content-wrapper {
  flex: 1;
}

.footer {
  background-color: #f5f5f5;
  padding: 20px 0;
  margin-top: 20px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}
</style>
