<template>
  <InvoiceFormComponent
    v-if="!showCheckout"
    @update-context="handleContextUpdate"
    ref="formRef"
  />
  <CheckoutComponent :context="context" ref="checkoutRef" />
  <div id="loadingIcon" v-if="isLoading">
  <div class="spinner"></div>
  </div>
  <div :class="{ 'error-message': isError, 'info-message': !isError }">
    {{ message }}
  </div>

</template>
<script>
import InvoiceFormComponent from "./components/InvoiceFormComponent.vue";
export default {
  name: "InvoiceComponent",
  components: {
    InvoiceFormComponent,
  },

  props: {},
  data() {
    return {
      service: "",
      isLoading:false,
      prix: "",
      servicesOfferts: [],
      message: "",
      isError: false,
      context: {
        id: "",
        date: new Date().toISOString(),
        billToName: "",
        billToAddress: "",
        address: "",
        total: "",
        notes: "",
        orderedProducts: [
          /* array of IProduct instances */
        ],
      },
    };
  },
  methods: {
    showMessage(success) {
      if (success) {
        this.message = "success";
        this.isError = false;
      } else {
        this.message = "error";
        this.isError = true;
      }
    },

    addService() {
      this.servicesOfferts.push({ service: this.service, prix: this.prix });
      this.service = "";
      this.prix = "";
    },
    async envoyerFacture() {
          this.isLoading=true;

          this.$refs.checkoutRef
            .submitInfo()
            .then(() => {
              this.isLoading=false
              this.showMessage(true);
            })
            .catch((error) => {
              console.error(error);
              this.isLoading=false
              this.showMessage(false);
            });
    },
    submitFormContent() {
  
      if (this.$refs.formRef) {
        this.$refs.formRef.submitContext();
      }
    },

  },
};
</script>

<style scoped>
#listeServices {
  text-align: left;
}

.error-message {
  color: red;
}

.info-message {
  color: blue;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s infinite linear;
}

#loadingIcon{
  display: flex;
  justify-content: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
