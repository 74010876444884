import { createRouter, createWebHistory } from 'vue-router'
// import HomeComponent from '../Home.vue'
import InvoiceComponent from '../Invoice.vue'
import Login from '../Login.vue';
import PortfolioComponent from '../portfolio.vue';
import {jwtDecode} from 'jwt-decode';


const routes = [
  { path: '/', component: PortfolioComponent },
  { 
    path: '/invoice',
    name: 'invoice',  
    component: InvoiceComponent,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    component: Login,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

function isTokenValid(token) {
  if (!token) return false;
  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decoded.exp && decoded.exp > currentTime;
  } catch (error) {
    console.error('Invalid token:', error);
    return false;
  }
}

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
  const isAuthenticated = isTokenValid(token);


  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next({ path: '/login' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
