<template>
  <div class="invoice-box">
    <table cellpadding="0" cellspacing="0">
      <tr class="top">
        <td colspan="2">
          <table class="top-table">
            <tr>
              <td class="left-td" style="width:50%">
                Invoice #: {{ context.id }}<br>
                Créé: {{ context.date }}<br>
              </td>
              <td class="right-td" style="width:50%">
                Facture de: <br>
                Philippe Noël Services Web <br>
              </td>
            </tr>
          </table>
        </td>
      </tr>

      <tr class="information">
        <td colspan="2">
          <table>
            <tr>
              <td>
                Facturé à: <br>
                {{ context.selectedClient.name }}<br>
                {{ context.selectedClient.address }}<br>
                {{ context.selectedClient.address2 }}
              </td>
            </tr>
          </table>
        </td>
      </tr>

      <tr>
        <td>
          <h2>Détails des services</h2>
        </td>
      </tr>
      <tr class="heading">
        <td>Item</td>
        <td>Price</td>
      </tr>

      <template v-if="context.serviceOffered && context.serviceOffered.length > 0">
  <tr v-for="(product, index) in context.serviceOffered" :key="index" class="item" :class="{ last: index === context.serviceOffered.length - 1 }">
    <td>{{ product.service }}</td>
    <td>{{ product.prix }}</td>
  </tr>
</template>


      <tr class="total">
        <td></td>
        <td>Total: {{ context.total }}</td>
      </tr>
    </table>

    <div class="notes">
      {{ context.notes }}
    </div>
  </div>
</template>
<script>


export default {
  name: "CheckoutComponent",
  components: {
    
  },
  props: {
    context: Object
  },
  data() {
    return {

    };
  },
  methods: {   
    async submitInfo() {
      console.log("submitInfo");
      console.log(this.context);
      try {
        const response = await fetch(
          `${process.env.VUE_APP_BACKEND_URL}/send-email`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(this.context),
          }
        );
        console.log("response", response);

        if (!response.ok) {
          throw new Error(`Email could not be sent: ${response.statusText}`);
        }

        const responseData = await response.json();
        console.log("Email sent successfully", responseData);
        this.resetForm();
        return "Email sent successfully";
      
      } catch (error) {
        
        throw new Error(error);
       
      }
    },
    // resetForm() {
    //   this.context = {
    //     id: "",
    //     email: "",
    //     date: "",
    //     billToName: "",
    //     billToAddress: "",
    //     billToAddress2: "",
    //     total: "",
    //     notes: "",
    //     servicesOffered: [],
    //   };
    // },
  },
};
</script>

<style scoped>
p {
  color: blue;
}

.invoice-box {
      max-width: 800px;
      margin: auto;
      padding: 30px;
      border: 1px solid #eee;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
      font-size: 16px;
      line-height: 24px;
      font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
      color: #555;
    }

    .invoice-box table {
      width: 100%;
      line-height: inherit;
      text-align: left;
    }

    .invoice-box table td {
      padding: 5px;
      vertical-align: top;
    }

    .invoice-box table tr td:nth-child(2) {
      text-align: right;
    }

    .invoice-box table tr.top table td {
      padding-bottom: 20px;
    }

    .invoice-box table tr.top table td.title {
      font-size: 45px;
      line-height: 45px;
      color: #333;
    }

    .invoice-box table tr.information table td {
      padding-bottom: 40px;
    }

    .invoice-box table tr.heading td {
      background: #eee;
      border-bottom: 1px solid #ddd;
      font-weight: bold;
    }

    .invoice-box table tr.details td {
      padding-bottom: 20px;
    }

    .invoice-box table tr.item td {
      border-bottom: 1px solid #eee;
    }

    .invoice-box table tr.item.last td {
      border-bottom: none;
    }

    .invoice-box table tr.total td:nth-child(2) {
      border-top: 2px solid #eee;
      font-weight: bold;
    }

    .invoice-box .notes {
      margin-top: 20px;
      padding: 8px;
      background: #eee;
    }

    .top-table {
      width: 100%;
    }

    .left-td {
      text-align: left;
    }

    .right-td {
      text-align: right;
    }
</style>./ToastComponent.vue
