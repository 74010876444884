<template>
  <div class="max-width-700">
    <h1>Login</h1>
    <form @submit.prevent="login">
      <div class="form-group">
        <input
          type="email"
          class="form-control my-4"
          id="email"
          aria-describedby="emailHelp"
          placeholder="Enter email"
          v-model="email"
        />
     
      </div>
      <div class="form-group">
        <input
          type="password"
          class="form-control my-4"
          id="password"
          placeholder="Password"
          v-model="password"
        />
      </div>

      <button type="submit" class="btn btn-black mt-4">Login</button>
    </form>
  </div>
</template>

<script>
export default {
  name: "LoginComponent",
  components: {},
  data() {
    return {
      email: "john@example.com",
      password: "password123",
    };
  },
  methods: {
   async login() {
    try {
        const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/login`, {
            method: 'POST', 
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
                    email: this.email,
                    password: this.password
                }),
        });
        if (!response.ok) {
            throw new Error('Login request failed.');
        }
        const data = await response.json();
        if (data.token && data.user.id && data.user.email) {
            localStorage.setItem('token', data.token);
            localStorage.setItem('uid', data.user.id);
            localStorage.setItem('email', data.user.email);
            this.$router.push({ name: "invoice" });
        } else {
            throw new Error('Token, UID, or email is missing in the response');
        }
        return data;
    } catch (error) {
        console.error('Error logging in:', error);
        return null;
    }





      

    },
  },
};
</script>
