<template>
  <div class="invoice">
    <section class="me-4">
      <h1>Invoice</h1>
      <div class="container margin max-width-700">
        <label for="text" class="left">Nom du client</label>

        <div class="d-flex form-group text-left">
          <select
            name="text"
            class="form-control"
            id="text"
            aria-describedby="facture"
            v-model="context.selectedClient"
          >
            <option value="" disabled selected>Nom du facturé</option>
            <option
              v-for="client in listeClients"
              :value="client"
              :key="client.id"
            >
              {{ client.name }}
            </option>
          </select>
          <AddClientComponent v-if="showModal"></AddClientComponent>

          <button class="btn btn-black ms-2" @click="popModal">
            Ajouter client
          </button>
        </div>

        <div class="d-flex form-group text-left">
          <select
            name="services"
            class="form-control"
            id="services"
            aria-describedby="facture"
            v-model="service"
          >
            <option selected :value="services[0]">{{ services[0] }}</option>

            <option
              v-for="(serviceItem, index) in services"
              :key="index"
              :value="serviceItem"
            >
              {{ serviceItem }}
            </option>
          </select>

          <input
            v-if="service === 'Taux horaire'"
            type="number"
            class="form-control ms-2"
            id="heures"
            placeholder="Heures"
            v-model="nbHeures"
            @change="calculTauxHoraire"
          />

          <input
            v-if="service === 'Autre'"
            type="text"
            class="form-control ms-2"
            id="autre"
            placeholder="Écrivez autre"
            v-model="newService"
            @keyup.enter="addNewService"
            @blur="addNewService"
          />

          <input
            type="number"
            name="prix"
            class="form-control ms-2"
            id="prix"
            aria-describedby="facture"
            placeholder="Prix"
            v-model="prix"
          />

          <button class="btn btn-black ms-2" @click="addService">
            Ajouter
          </button>
        </div>
        <p
          id="listeServices"
          v-for="(service, index) in serviceOffered"
          :key="index"
        >
          {{ service.service }} {{ service.prix }}$
        </p>

        <div class="form-group text-left">
          <textarea
            name="note"
            class="form-control"
            id="note"
            placeholder="Note"
            rows="4"
            v-model="context.notes"
          ></textarea>
        </div>
      </div>
    </section>
    <section class="ms-4">
    <h1>Aperçu</h1>
    <CheckoutComponent :context="context" ref="checkoutRef" />
  </section>


  </div>

  <div id="loadingIcon" v-if="isLoading">
  <div class="spinner"></div>
  </div>
  <button @click="envoyerFacture" v-if="!isLoading" class="btn btn-black mt-4 w-25">Envoyer reçu</button>
  
  <div v-if="message!=''" :class=" { 'alert alert-danger my-2': isError, 'alert alert-primary my-2': !isError }" role="alert">
    {{ message }}
  </div>

  </template>

<script>
import AddClientComponent from "./AddClientComponent.vue";
import CheckoutComponent from "./CheckoutComponent.vue";

export default {
  name: "InvoiceFormComponent",
  components: {
    CheckoutComponent,
    AddClientComponent,
  },
  props: {},
  mounted() {
    this.fetchClients().then((result) => {
      this.listeClients = result;
    });
  },
  data() {
    return {
      services: ["Taux horaire", "Autre"],
      service: "Taux horaire",
      newService: "",
      prix: 0,
      message: "",
      isError: true,
      isLoading:false,

      tauxHoraire: 80,
      nbHeures: 0,
      listeClients: [],
      showModal: false,

      context: {
        id: "",
        date: new Date().toLocaleDateString("en-CA", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }),
        total: 0,
        notes:
          "Merci de payer la facture dès la réception du document \n Par Intérac : philippe.noel.49@hotmail.com \n Question : service \n Réponse: web.",
        servicesOffered: [],
        selectedClient: {},
      },
    };
  },
  computed: {},
  methods: {
    showMessage(success, message) {
      if (success) {
        this.message = message;
        this.isError = false;
      } else {
        this.message = message;
        this.isError = true;
      }
    },

    addService() {
      if (this.service != "" && this.prix != "")
        this.context.servicesOffered.push({
          serviceName: `${this.service} ${
            this.nbHeures > 0 ? `${this.nbHeures}h` : ""
          }`,
          price: this.prix,
        });
      this.context.total = this.prix;
      this.nbHeures = null;
      this.service = "";
      this.prix = "";
    },

    popModal() {
      this.showModal = !this.showModal;
    },

    addNewService() {
    if (this.newService.trim()) {
      this.services.push(this.newService.trim());
      this.service = this.newService;
      this.newService = '';
    }
  },
    async envoyerFacture() {
          this.isLoading=true;

          this.$refs.checkoutRef
            .submitInfo()
            .then((response) => {
              this.isLoading=false
              this.showMessage(true, response.message );
            })
            .catch((error) => {
              console.error(error);
              this.isLoading=false
              this.showMessage(false, error.message);
            });
    },


    calculTauxHoraire() {
      this.prix = this.nbHeures * this.tauxHoraire;
      return this.prix;
    },

    async fetchClients() {


      try {
        const response = await fetch(
          `${process.env.VUE_APP_BACKEND_URL}/clients`
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Could not fetch clients:", error);
      }
    },
  },
};
</script>

<style scoped>
.left {
  text-align: left !important;
}

.invoice{
  display:flex;
  flex-direction: row;
}

.error-message {
  color: red;
}

.info-message {
  color: blue;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s infinite linear;
}

#loadingIcon{
  display: flex;
  justify-content: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
